import React from 'react'
import { Link, graphql } from 'gatsby'

import Img from 'gatsby-image/withIEPolyfill'
import BackgroundImage from 'gatsby-background-image'

import Layout from '../components/layout'
import SEO from '../components/seo'

import styled from 'styled-components'
import { Container, PageWrapper } from '../components/common/container'
import { Title } from '../components/common/title'
import { HeroSection } from '../components/common/hero-section'
import KnowledgeBaseSearchMini from '../components/knowledge-base/knowledge-base-search-mini'
import { CustomCard } from '../components/common/card'

import colors from '../data/color-pallette'

import Cell from 'react-md/lib/Grids/Cell'
import Grid from 'react-md/lib/Grids/Grid'
import CardTitle from 'react-md/lib/Cards/CardTitle'
import CardText from 'react-md/lib/Cards/CardText'

import breakpoints from '../data/breakpoints'

const HelpCard = styled(CustomCard)`
  text-align: left;

  a {
    text-decoration: none;
  }

  h3 {
    white-space: normal;
    justify-content: left;
  }

  .text-wrapper {
    padding: 8px;

    h3 {
      margin: 0;
    }
  }
`
const HelpImage = styled(Img)`
  min-height: 100px;
  height: auto;

  @media (min-width: ${breakpoints.sm}) {
    min-height: 330px;
  }
`

// https://thenounproject.com/search/?q=change&i=2277883

const CustomHeroSection = styled(HeroSection)`
  height: 300px;
`

const HeroTitle = styled(Title)`
  color: ${colors.white};
  margin-bottom: 12px;
`

const SectionTitle = styled(Title)`
  font-size: 32px;
  margin-bottom: 12px;
`

const KnowledgeBaseCard = styled(CustomCard)`
  .popular-kb-articles-title {
    text-align: center;
    margin-top: 20px;
  }
  h4 {
    margin-top: 20px;
    text-align: left;
  }

  a {
    text-decoration: none;
    color: ${colors.secondary};
  }
`

const KnowledgeBaseLink = styled(Link)`
  display: block;
  padding: 0 30px;

  hr {
    color: #ddd;
    width: 80%;
    margin: 20px auto 0;
    border-top: 0;
    border-left: 0;
  }

  &:last-child hr {
    display: none;
  }
`

const HelpCell = styled(Cell)`
  @media (max-width: ${breakpoints.lg}) {
    margin-left: auto;
    margin-right: auto;
  }
`

const IndexPage = props => {
  const {
    data: { heroImage, gettingStarted, knowledgeBasePosts },
  } = props

  return (
    <Layout>
      <SEO title="StreamParrot - The best branding available for your chat overlay" />
      <PageWrapper>
        <div>
          <BackgroundImage
            fluid={{
              base64: heroImage.childImageSharp.lowRes.dataURI,
              src: heroImage.childImageSharp.hiRes.dataURI,
            }}
          >
            <CustomHeroSection>
              <HeroTitle>Help and Support</HeroTitle>
            </CustomHeroSection>
          </BackgroundImage>
          <Container>
            <Grid>
              <HelpCell size={6}>
                <HelpCard>
                  <Link to={gettingStarted.frontmatter.path}>
                    <CardTitle
                      title={gettingStarted.frontmatter.title}
                    ></CardTitle>
                    <CardText>
                      <div className="text-wrapper">
                        <p>{gettingStarted.frontmatter.summary}</p>
                      </div>
                    </CardText>
                    <HelpImage
                      fluid={{
                        base64:
                          gettingStarted.frontmatter.featuredImage
                            .childImageSharp.sqip.dataURI,
                      }}
                    />
                  </Link>
                </HelpCard>
              </HelpCell>
              <HelpCell size={6}>
                <KnowledgeBaseCard>
                  <Link to="/help/kb">
                    <CardTitle title="Knowledge Base" />
                  </Link>
                  <CardText>
                    <KnowledgeBaseSearchMini />
                    <h3 class="popular-kb-articles-title">
                      Popular KB Articles
                    </h3>
                    {knowledgeBasePosts.edges.map(
                      ({
                        node: {
                          frontmatter: { path, title, summary },
                        },
                      }) => {
                        return (
                          <KnowledgeBaseLink to={path}>
                            <h4>{title}</h4>
                            <p>{summary}</p>
                            <hr />
                          </KnowledgeBaseLink>
                        )
                      }
                    )}
                  </CardText>
                </KnowledgeBaseCard>
              </HelpCell>
            </Grid>
          </Container>
        </div>
      </PageWrapper>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query($SQIP_HERO_SHAPE_COUNT: Int, $SQIP_THUMB_SHAPE_COUNT: Int) {
    heroImage: file(relativePath: { regex: "/lifesaver.jpeg/" }) {
      ...HeroImage
    }
    helpPosts: allMarkdownRemark(
      filter: { frontmatter: { postType: { eq: "help" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 3
    ) {
      edges {
        node {
          html
          frontmatter {
            postType
            path
            title
            date(formatString: "MMMM DD, YYYY")
            summary
            featuredImage {
              childImageSharp {
                sqip(
                  numberOfPrimitives: $SQIP_THUMB_SHAPE_COUNT
                  blur: 0
                  width: 256
                  mode: 1
                ) {
                  dataURI
                }
              }
            }
          }
        }
      }
    }
    gettingStarted: markdownRemark(
      frontmatter: { path: { eq: "/help/getting-started" } }
    ) {
      html
      frontmatter {
        postType
        path
        title
        date(formatString: "MMMM DD, YYYY")
        summary
        featuredImage {
          childImageSharp {
            sqip(
              numberOfPrimitives: $SQIP_THUMB_SHAPE_COUNT
              blur: 0
              width: 256
              mode: 1
            ) {
              dataURI
            }
          }
        }
      }
    }
    knowledgeBasePosts: allMarkdownRemark(
      filter: { frontmatter: { postType: { eq: "help/kb" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 3
    ) {
      edges {
        node {
          html
          frontmatter {
            postType
            path
            title
            date(formatString: "MMMM DD, YYYY")
            summary
            featuredImage {
              childImageSharp {
                sqip(
                  numberOfPrimitives: $SQIP_THUMB_SHAPE_COUNT
                  blur: 0
                  width: 256
                  mode: 1
                ) {
                  dataURI
                }
              }
            }
          }
        }
      }
    }
  }
`
