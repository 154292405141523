import { Link, navigate } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import Button from 'react-md/lib/Buttons/Button'
import FontIcon from 'react-md/lib/FontIcons/FontIcon'
import TextField from 'react-md/lib/TextFields/TextField'

import colors from '../../data/color-pallette'
import { Container } from '../common/container'
import { Title } from '../common/title'
import breakpoints from '../../data/breakpoints'

const SearchButton = styled(Button)`
  &.md-btn {
    margin: 0;
  }
`

const KnowledgeBaseSearchMini = ({ items }) => {
  const [searchInput, setSearchInput] = useState('')

  const submitForm = event => {
    event.preventDefault()
    // add stopPropagation if adding a button?
    navigate(`/help/kb?search=${searchInput}`)
    return false
  }
  return (
    <form action="/help/kb" method="get" onSubmit={submitForm}>
      <TextField
        id="knowledge-base-search-mini"
        type="text"
        name="search"
        placeholder="Search"
        value={searchInput}
        onChange={event => {
          setSearchInput(event)
        }}
        leftIcon={<FontIcon>search</FontIcon>}
        rightIcon={
          <SearchButton flat type="submit">
            Search
          </SearchButton>
        }
      />
    </form>
  )
}

KnowledgeBaseSearchMini.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
}

KnowledgeBaseSearchMini.defaultProps = {
  items: [],
}

export default KnowledgeBaseSearchMini
